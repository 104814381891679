export const defaultTheme = {
    botAvatarInitials: 'WF',
    // userAvatarInitials: firstName ? `${firstName.slice(0, 1)}${lastName.slice(0, 1)}` : 'ME',
    accent: '#00c8a0',

    hideUploadButton: true,
    sendBoxBackground: '#222a39',
    sendBoxPlaceholderColor: '#cccccc',
    sendBoxTextColor: '#ffffff',
    sendBoxBorderTop: 'solid 1px #ffffff',

    backgroundColor: '#222a39',

    bubbleBackground: '#06141f',
    bubbleTextColor: '#cdd0d2',
    bubbleBorderStyle: 'none',
    bubbleBorderRadius: 8,

    // User
    // userAvatarImage: 'https://github.com/compulim.png?size=64',
    userAvatarBackgroundColor: '#00c8a0',
    bubbleFromUserBackground: '#00c8a0',
    bubbleFromUserTextColor: '#06141f',
    bubbleFromUserBorderStyle: 'none',
    bubbleFromUserBorderRadius: 8,

    // Suggested actions
    suggestedActionBackground: '#333d4e',
    suggestedActionBorderRadius: 8,
    suggestedActionBorderWidth: 1,
    suggestedActionBorderColor: '#4c5661',
    suggestedActionTextColor: '#ffffff',
    // suggestedActionLayout: 'stacked',

    // toastSuccessColor: '#00c8a0'

    // typingAnimationBackgroundImage: 'none'
};