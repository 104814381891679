import zoid from 'zoid';
import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import ReactWebChat, {
  createDirectLine,
  createCognitiveServicesSpeechServicesPonyfillFactory,
  createStore
} from 'botframework-webchat';
import './App.css';
import { defaultTheme } from './themes/defaultTheme';
import botAvatarImage from './images/botAvatar.png';

declare global {
  interface Window {
    webChatComponent: any;
    xprops: any;
  }
}

const {
  REACT_APP_allowedParentDomains
} = process.env;

window.webChatComponent = zoid.create({
  tag: 'webchat-component',
  // url: new URL('http://localhost:3000/', window.location.href).href,
  url: new URL('https://wefin.tradesmarter.com', window.location.href).href,
  allowedParentDomains: REACT_APP_allowedParentDomains?.split(','),
  dimensions: {
    width: '100%',
    height: '100%'
  },
  autoResize: {
    width: false,
    height: true,
  },
  props: {
    token: {
      type: 'string',
      required: true
    },
    speechToken: {
      type: 'string',
      required: true
    },
    userId: {
      type: 'string',
      required: true
    },
    userName: {
      type: 'string',
      required: true
    },
    tz: {
      type: 'number',
      required: false
    },
    theme: {
      type: 'object',
      required: false
    }
  }
});

function App(props: any) {
  const [token, setToken] = useState<string>();
  const [speechToken, setSpeechToken] = useState<string>();
  const [userName, setUserName] = useState<string>();
  const [userId, setUserId] = useState();
  const [tz, setTz] = useState();
  const [webchatStyle, setWebChatStyle] = useState();
  const [containerStyle, setContainerStyle] = useState({});
  const [loadingStyle, setLoadingStyle] = useState({});
  const [webSpeechPonyfillFactory, setWebSpeechPonyfillFactory] = useState();

  useEffect(() => {
    if (window.xprops) {
      const {
        userId,
        userName,
        token,
        speechToken,
        tz,
        theme
      } = window.xprops;
      setUserId(userId);
      setUserName(userName);
      setToken(token);
      setSpeechToken(speechToken);
      setTz(tz || 0);
      setStyles(theme || defaultTheme);

      window.xprops.onProps(({ token, speechToken }) => {
        setToken(token || undefined);
        setSpeechToken(speechToken || undefined);
      });
    }
    // return window.xprops.onError((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (speechToken) createwebSpeechPonyfillFactory(speechToken);
  }, [speechToken]);

  const setStyles = (theme: React.SetStateAction<any>) => {
    setWebChatStyle({
      botAvatarImage,
      ...theme
    });
    setContainerStyle({
      backgroundColor: theme.backgroundColor,
      color: theme.sendBoxTextColor
    });
    setLoadingStyle({
      background: theme.bubbleBackground,
      color: theme.bubbleTextColor,
      padding: 20,
      borderRadius: theme.bubbleBorderRadius,
      borderStyle: 'none',
      borderWidth: 1,
      minHeight: 38
    });
  }

  const createwebSpeechPonyfillFactory = async (spToken: string | undefined) => {
    const webSpeechPonyfillFactory = await createCognitiveServicesSpeechServicesPonyfillFactory({
      credentials: {
        region: 'southeastasia',
        authorizationToken: spToken,
      },
      textNormalization: 'itn'
    });
    setWebSpeechPonyfillFactory(() => webSpeechPonyfillFactory);
  }

  const dispatchActivities = (() => (
    { dispatch }: any) => (next: Dispatch<any>) => (action: any) => {
      switch (action.type) {
        case 'DIRECT_LINE/CONNECT_FULFILLED':
          dispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: {
              name: 'webchat/join',
              value: { language: window.navigator.language, tz }
            }
          });
          break;

        // case 'DIRECT_LINE/UPDATE_CONNECTION_STATUS': {

        // if (
        //   action.payload
        // && action.payload.connectionStatus ===
        // DirectLineConnectionStatus.ExpiredToken
        // ) {
        // handleExpiredToken();
        // }

        // if (
        //   action.payload &&
        //   action.payload.connectionStatus ===
        //   DirectLineConnectionStatus.Online
        // ) {
        //   handleConnectionOnline();
        // }
        // break;
        // }
      }
      return next(action);
    }
  )();

  const store = useMemo(() => createStore({}, dispatchActivities), [dispatchActivities]);
  const directLine = useMemo(() => createDirectLine({ token }), [token]);

  let containerClassName = 'appContainer';
  if (!token || !speechToken || !webSpeechPonyfillFactory) {
    containerClassName += ' center-content';
  }
  return (
    <div className={containerClassName} style={containerStyle}>
      {(!token || !speechToken || !webSpeechPonyfillFactory) &&
        <div className="loading" style={loadingStyle}>Loading<br />:)</div>
      }
      {token && speechToken && webSpeechPonyfillFactory &&
        <ReactWebChat
          userID={userId}
          username={userName}
          directLine={directLine}
          styleOptions={webchatStyle}
          store={store}
          // activityMiddleware={middleware()}
          selectVoice={(voices: any[], activity: any) => voices.find(({ name }) => /GuyNeural/iu.test(name))}
          webSpeechPonyfillFactory={webSpeechPonyfillFactory}
        />
      }
    </div>
  );
}

export default App;
